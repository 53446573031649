import React, { useEffect, useState } from "react";
import "../css/dashboard.scss";
import Header from "../components/header/Header";
import { getPartnerEpisodeView, getPartnerEpisodeReport } from "../services/partner";
import { useParams } from "react-router-dom";

const EpisodeDashboard = () => {
  const [data, setData] = useState([]);

  const {seasonId} = useParams();

  useEffect(() => {
    getPartnerEpisodeView(seasonId)
      .then((res) => {
        setData(res.data.sort((a, b) => new Date(a.add_date) - new Date(b.add_date)));
      });
  }, []);

  const handleDownload = ({id, name}) => {
    getPartnerEpisodeReport(id)
      .then((res) => {
        // Create a blob from the response data
        const blob = new Blob([res.data], { 
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        
        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);
        
        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        const todayDate = new Date().toISOString().split('T')[0];
        link.setAttribute('download', `${name}_report_${todayDate}.xlsx`);
        
        // Append link to body, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Clean up the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading report:', error);
      });
  };

  return (
    <div className="box">
      <Header />
      <div className="filter"></div>
      <div className="all-center">
        <div className="box-table">
          <table>
            <tbody>
              <tr className="text-center">
                <th>IMAGE</th>
                <th>NAME</th>
                <th>DATE</th>
                <th>VIEWS COUNT</th>
                <th>ACTIONS</th>
              </tr>

              {data.map((row) => (
                <tr key={row.id}>
                  <td>
                    <img
                      width={60}
                      src={
                        "https://metaflixassets.fra1.cdn.digitaloceanspaces.com/" +
                        row.main_picture
                      }
                      alt=""
                    />
                  </td>
                  <td>{row.name}</td>
                  <td> {new Date(row.add_date).toDateString()}</td>
                  <td>{row.hit}</td>
                  <td>
                    <button 
                      className="download-btn"
                      onClick={() => {
                        handleDownload(row);
                      }}
                    >
                      📊 Report
                    </button>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EpisodeDashboard;
